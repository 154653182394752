.wave {
  fill: #fff;
  width: 50px;
  height: 50px;
}
.path {
  animation: pulse 1s infinite;
}

@for $i from 1 through 9 {
  .path:nth-child(#{$i}) {
    animation-delay: $i * .15s;
  }
}

@keyframes pulse {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }

  50% {
    transform: scaleY(.7);
    transform-origin: 50% 50%;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}