@import "../../styles/variables.module";

.loader {
  min-height: 80vh;
}

.wrapper {
  flex-direction: column;
  display: flex;
  position: relative;

  .header {
    margin: 0 auto;
    width: 100%;
    z-index: $z-index-base;
    padding: 2.4rem 3.2rem;

    @include media("<=tablet") {
      padding: 2.4rem 1.6rem;
    }
    @include media(">desktop720p") {
      padding: 2.4rem 5rem;
    }

    @include media("<=phoneXL") {
      padding: 1.6rem 1.6rem;
    }
  }
}
