@import "/styles/variables.module";

body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .option {
      &:focus {
        outline: none;
        transform: scale(1.05);
      }
    }
  }
}

.wrapper {
  background-color: #344151;
  border-radius: 0.4rem;
  min-width: 25.9rem;

  .option {
    padding: 1.6rem;
    white-space: nowrap;
    font-size: 2.4rem;
    line-height: 2.6rem;
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  [data-navigate-row] + [data-navigate-row] {
    margin-top: 1.8rem;
  }

  .icon_wrapper {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.8rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
