@import "../../../styles/variables.module";

body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .podcast_chapter_wrapper {
      &:focus-within {
        background-color: rgba(93, 108, 146, 0.35);
      }
    }
    .podcast_chapter_controls_more {
      &:focus {
        background-color: rgba(93, 108, 146, 0.35);
        outline: none;
        transform: scale(1.05);
      }
    }
  }
}

body:global(.low-performance) {
  .podcast_chapter_wrapper,
  .hover_overlay,
  .podcast_chapter_seek_bar_inner,
  .podcast_chapter_controls_more {
    transition: none !important;
  }
}

.podcast_chapter_wrapper {
  display: flex;
  border-radius: $border-radius;
  transition: background-color 0.5s;
  padding: 1.4rem 2.2rem;
  margin: 3.9rem -2.2rem;

  @include media("<=tablet") {
    padding: 1.4rem 1.4rem;
    margin: 3.9rem -1.4rem;
  }
  @include media("<=phoneXL") {
    padding: 1.2rem;
    margin: 2.9rem -0.8rem;
  }

  &:hover,
  &.active {
    background-color: rgba(93, 108, 146, 0.35);
  }

  &.active {
    .cover_wrapper {
      .hover_overlay {
        opacity: 1;
      }
    }
  }

  .podcast_chapter_main {
    display: flex;
    flex-grow: 2;
    cursor: pointer;

    &:hover,
    &:focus {
      border: 0;
      outline: 0;
      .cover_wrapper {
        .hover_overlay {
          opacity: 1;
        }
      }
    }
  }

  .cover_wrapper {
    margin-right: 1.6rem;
    cursor: pointer;
    position: relative;
    width: 14rem;
    height: 14rem;

    html[dir="rtl"] & {
      margin-right: 0;
      margin-left: 1.6rem;
    }

    @include media("<=phoneXL") {
      display: none;
    }

    @include media("<=tablet") {
      width: 10.5rem;
      height: 10.5rem;
    }

    .cover {
      width: 100%;
      height: 100%;
      border-radius: $border-radius;
      display: block;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .hover_overlay {
      transition: all 0.25s;
      opacity: 0;
      background-color: rgba($color-black, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: $border-radius;

      .svg_wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 60%;
          width: 60%;
        }
      }
    }
  }
}

.podcast_chapter {
  color: #fff;
  display: grid;
  grid-template-columns: min-content 1fr min-content min-content;
  grid-template-rows: min-content 1fr min-content min-content;
  flex: 1;

  @include media("<=tablet") {
    position: relative;
  }
}

.podcast_chapter_date {
  color: $color-text-muted;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.7rem;
  margin-bottom: 2rem;
  grid-column-start: 1;
  grid-column-end: 3;

  @include media("<=tablet") {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

.podcast_chapter_details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 3;

  h3 {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 3.5rem;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0.5rem;
    padding-top: 0.5rem;

    @include media("<tablet") {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  .podcast_chapter_description {
    font-size: 2.4rem;
    color: rgba($color-light, 0.6);
    line-height: 2.65rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include media("<tablet") {
      font-size: 1.6rem;
    }
  }
}

.podcast_chapter_seek_time {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: 2;
  padding-bottom: 0.3rem;

  p {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 2.6rem;
    color: $color-text-muted;
    margin-right: 1.6rem;
    white-space: nowrap;

    html[dir="rtl"] & {
      margin-right: 0;
      margin-left: 1.6rem;
    }

    @include media("<=tablet") {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  }
}

.podcast_chapter_seek_wrapper {
  grid-row-start: 3;
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;

  @include media("<=phoneXL") {
    margin-top: 1.6rem;
    grid-row-start: 4;
    grid-column-start: 1;
  }

  .podcast_chapter_seek_bar {
    height: 0.4rem;
    background: #2e384b;
    flex-grow: 1;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    grid-column-start: 2;
    grid-column-end: 3;

    @include media("<=tablet") {
      height: 0.4rem;
    }
  }

  .podcast_chapter_seek_bar_inner {
    height: 0.4rem;
    background: #fff;
    border-radius: 4px;
    width: 0%;
    transition: width 0.3s;

    @include media("<=tablet") {
      height: 0.4rem;
    }

    &.podcast_chapter_progress_bar_inner {
      background-color: $color-progress;
    }
  }

  .text_download_progress {
    margin-left: 1.4rem;
    font-size: 1.2rem;
    color: $color-progress;
  }
}

.podcast_chapter_controls_wrapper {
  grid-row-start: 2;
  grid-row-end: 4;
  height: 7.1rem;
  width: 7.1rem;
  margin-left: 2.4rem;
  border-radius: 50%;
  border: 0.7rem solid rgb(26, 27, 28);
  display: flex;
  justify-self: end;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 5.5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  html[dir="rtl"] & {
    margin-right: 2.4rem;
    margin-left: 0;
  }

  @include media("<=tablet") {
    width: 5rem;
    height: 5rem;
    margin-left: 1rem;
    margin-top: 4.5rem;
  }

  svg {
    height: 3.8rem;
    width: 3.8rem;

    @include media("<=tablet") {
      height: 2.3rem;
      width: 2.3rem;
    }
  }

  &.podcast_chapter_controls_more {
    position: relative;
    border: 0;
    transition: 0.5s all;

    svg {
      height: 7.1rem;
      width: 7.1rem;

      @include media("<=tablet") {
        height: 5rem;
        width: 5rem;
      }
    }

    .dropdown_wrapper {
      display: none;
    }

    &.show_dropdown {
      .dropdown_wrapper {
        z-index: 1;
        display: block;
        position: absolute;
        top: 90%;
        right: 40%;

        svg {
          transform: rotate(0deg);
          width: 100%;
          height: 100%;

          &.check_icon {
            transform: scale(1.5);
          }
        }
      }
    }
  }

  &.filled_button {
    border-color: $color-accent;

    svg path {
      fill: $color-accent;
    }
  }
  &.show_progress {
    border: 0;

    .podcast_chapter_controls_offline_svg_wrapper {
      position: absolute;
    }
  }
  .podcast_chapter_controls_progress {
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
