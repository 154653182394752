@import "../../../styles/variables.module";

body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .action_button {
      &:focus {
        outline: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.1);
        background-color: rgba(93, 108, 146, 0.35);
      }
    }

    .expander {
      button:focus {
        outline: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.1);
        background-color: rgba(93, 108, 146, 0.35);
      }
    }

    .tab {
      &:focus {
        outline: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.1);
      }
    }
  }
}

body:global(.low-performance) {
  .action_button,
  .tab {
    transition: none !important;
  }
  .expander button,
  .expander svg {
    transition: none !important;
  }
}

.wrapper {
  margin: 0 auto;
  width: calc(100vw - 2rem);
  padding: 2.4rem 3.2rem;
  z-index: $z-index-base;

  @include media("<=tablet") {
    padding: 2.4rem 1.6rem;
  }
  @include media(">desktop720p") {
    padding: 2.4rem 5rem;
  }

  @include media("<=phoneXL") {
    padding: 0.8rem 1.6rem 14.2rem;
  }
}

.podcast_cover {
  display: flex;
  align-items: center;

  img {
    height: 29rem;
    width: 29rem;
    object-fit: cover;
    border-radius: $border-radius;
    margin-right: 3.6rem;
    box-shadow: 0px 0px 54px #000000;

    html[dir="rtl"] & {
      margin-right: 0;
      margin-left: 3.6rem;
    }

    @include media("<=tablet") {
      width: 20rem;
      height: 20rem;
      margin-right: 1.6rem;
    }

    @include media("<=phoneXL") {
      width: 12rem;
      height: 12rem;
    }
  }

  .podcast_details {
    h1 {
      font-size: 6.4rem;
      font-weight: 700;
      line-height: 7.1rem;
      letter-spacing: -0.02em;
      color: #ffffff;
      margin-bottom: 2rem;
      vertical-align: top;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include media("<=tablet") {
        font-size: 3.2rem;
        line-height: 1.1;
        -webkit-line-clamp: 2;
      }

      @include media("<=phoneXL") {
        font-size: 2.4rem;
        margin-bottom: 0.8rem;
      }
    }
  }

  .buttons_wrapper {
    display: flex;
  }

  .action_button {
    border-radius: 100%;
    background-color: $color-light-transparent-15;
    width: 7.2rem;
    height: 7.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    transition: 0.5s all;

    @include media("<=tablet") {
      padding: 0;
      width: 6rem;
      height: 6rem;
      flex-basis: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      display: block;
      width: 3.6rem;
      height: 3.6rem;

      @include media("<=tablet") {
        width: 3.2rem;
        height: 3.2rem;
      }
    }

    &.play_button {
      svg {
        display: block;
        width: 6.4rem;
        height: 6.4rem;

        @include media("<=tablet") {
          width: 4.2rem;
          height: 4.2rem;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 2rem;

      html[dir="rtl"] & {
        margin-left: 2rem;
        margin-right: 0;
      }
    }

    @include media("<=tablet") {
      padding: 0;
    }

    &.favorites_button {
      svg {
        margin-top: 0.5rem;
      }
    }
  }
}

.podcast_description {
  color: $color-text-muted;
  overflow: hidden;

  &_container {
    margin-top: 4.2rem;
    margin-bottom: 4.2rem;
    &.expandable {
      margin-bottom: 1.2rem;

      .description {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    &.expanded {
      .description_container {
        max-height: unset;
      }

      .description {
        display: block;
      }
    }
  }

  @include media("<=tablet") {
    display: flex;
  }
  h2 {
    font-size: 3.2rem;
    color: $color-light;
    margin-bottom: 1.4rem;
    font-weight: 700;
  }

  .description_container {
    max-height: 7.8rem;
  }

  .description {
    line-height: 2.6rem;
    font-weight: 500;
    color: $color-light;
    font-size: 2.4rem;
    max-width: 90%;

    @include media("<=tablet") {
      max-width: 90%;
    }
  }
}

.website_button {
  margin-top: 0.4rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  height: 5.2rem;
  color: $color-light;
  font-size: 2.4rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 1px;
  display: flex;
  text-align: left;
  
  svg {
    margin-right: 0.8rem;
    
    html[dir="rtl"] & {
      margin-right: 0;
      margin-left: 0.8rem;
    }
  }
  
  @include media("<=tablet") {
    font-size: 2rem;
  }
  @include media("<phoneL") {
    font-size: 1.6rem;
  }
}

.podcast_chapter_list_wrapper {
  color: #fff;

  .loader {
    padding: 4.8rem 3.2rem;
    display: flex;
    justify-content: center;
    svg {
      width: 4.8rem;
      height: 4.8rem;
    }
  }
}

.expander {
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;

  button {
    transition: 0.5s all;
    border-radius: $border-radius-small;
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
  svg {
    transition: 0.25s transform;
    width: 4.2rem;
    height: 4.2rem;
  }
}


.tabs {
  display: flex;

  &_wrapper {
    padding: 1.6rem 0;
    @include media("<tablet") {
      overflow-x: auto;
      padding: 0.8rem 0;
    }
  }

  @include media("<tablet") {
    justify-self: flex-start;
  }

  .tab {
    color: $color-text-gray;
    text-transform: uppercase;
    margin-right: 1.6rem;
    padding: 1.5rem 2.4rem;
    border-radius: $border-radius-tabs;
    font-size: 2.4rem;
    line-height: 1;
    font-weight: 600;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 3px solid $color-text-gray;
    transition: 0.5s all;

    &.active {
      background-color: $color-primary;
      color: $color-light;
      border: 3px solid $color-primary;
    }

    @include media("<=phoneXL") {
      font-size: 1.6rem;
      padding: 0.8rem 1.6rem;
    }
  }
}
