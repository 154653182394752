@import "../../../styles/variables.module";

.wrapper {
  position: fixed;
  top: 0;
  top: constant(safe-area-inset-top, 0);
  top: env(safe-area-inset-top, 0);
  left: 0;
  right: 0;
  bottom: 0;
  bottom: constant(safe-area-inset-bottom, 0);
  bottom: env(safe-area-inset-bottom, 0);
  background-color: $color-black;
  z-index: $player-fullscreen-z-index + 1;
  display: flex;
  height: 100%;
  align-items: center;
  overflow: auto;
  justify-content: center;
}
.no_account {
  display: flex;
  max-width: 110rem;
  justify-content: center;
  padding: 7.2rem 0;
  flex-wrap: wrap;
  position: relative;
  margin: 0 auto;

  @include media(">=desktop768p", "height>tablet") {
    padding: 16rem 0 8rem;
  }

  @include media("height<tablet") {
    padding: 7.2rem 0 8rem;
  }

  @include media("<tablet") {
    margin: 0.8rem auto;
    text-align: center;
    padding: 5.6rem 0;
  }

  .content {
    flex-basis: 78rem;
    .title {
      color: $color-light;
      font-size: 7.2rem;
      font-weight: 700;

      @include media("<desktop720p") {
        font-size: 4.8rem;
      }
      @include media("<tablet") {
        font-size: 3.2rem;
        padding: 2.4rem 0;
      }
    }

    .subtitle {
      color: rgba($color-light, 0.7);
      font-size: 4.8rem;
      margin-bottom: 3.2rem;

      @include media("<desktop720p") {
        font-size: 3.2rem;
      }
      @include media("<tablet") {
        font-size: 2rem;
      }

      &.with_icon {
        font-size: 3.8rem;
        display: flex;

        @include media("<desktop720p") {
          font-size: 2.8rem;
        }
        .icon_wrapper {
          margin-right: 3rem;
          height: 10rem;
          width: 10rem;
          border-radius: 50%;
          border: 0.7rem solid rgba(255, 255, 255, 0.15);
          flex: 0 0 10rem;
          display: flex;
          align-items: center;
          justify-content: center;

          @include media("<=tablet") {
            margin-right: 1rem;
          }

          svg {
            height: 6.4rem;
            width: 6.4rem;
          }
        }
      }
    }
    .button {
      justify-self: center;
      font-size: 3.2rem;

      @include media("<tablet") {
        width: auto;
        font-size: 2rem;
        margin: 0 auto;
        min-width: 72%;
      }
    }
  }

  .icon {
    margin: 0 8rem 0 0;
    flex-basis: 24rem;
    max-width: 100%;

    @include media("<desktop720p") {
      margin: 1.6rem 3.2rem;
      flex-basis: 18rem;
    }
    @include media("<tablet") {
      text-align: center;
      margin: 0 auto;
    }
    svg {
      width: 24rem;
      height: 24rem;
      @include media("<desktop720p") {
        width: 18rem;
        height: 18rem;
      }
      @include media("<tablet") {
        width: 12rem;
        height: 12rem;
      }
    }
  }
}
